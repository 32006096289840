import React from "react"
import styles from "./highlight.module.scss"
import { useIntl, FormattedMessage } from "gatsby-plugin-intl"

export default ({
	image = "",
	imagePosition = "left",
	name = "",
	label = "name"
}) => {
	const intl = useIntl()
	const img = (
		<div>
			<img src={image} alt={intl.formatMessage({ id: `${name}.title` })} />
		</div>
	)
	return (
		<div className={styles.highlight}>
			{imagePosition === "left" && img}
			<div className={styles.text}>
				<div className={styles.name}><FormattedMessage id={`${name}.${label}`} /></div>
				<div className={styles.desc}><FormattedMessage id={`${name}.desc`} /></div>
			</div>
			{imagePosition === "right" && img}
		</div>
	)
}