import React from "react"
import styles from "./index.module.scss"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import SEO from "../../components/seo"
import Header from "../../components/buckmemo/header"
import Subheader from "../../components/buckmemo/subheader"
import Highlight from "../../components/buckmemo/highlight"
import PageFrame from "../../components/page-frame"
import OtherFeature from "../../components/buckmemo/other-feature"
import Footer from "../../components/footer"

export default ({ location }) => {
	const intl = useIntl()
	return (<>
		<SEO
			title={intl.formatMessage({ id: "buckmemo.home" })}
			pathname={location.pathname}
			siteName="buckmemo.name" />
		<Header homeLinked={false} />
		<div className={styles.headline}>
			<div className={styles.container}>
				<div className={styles.name}><FormattedMessage id="buckmemo.subname" /></div>
				<div className={styles.slogan}><FormattedMessage id="buckmemo.slogan" /></div>
				<a
					className={styles.appStoreBadge}
					href={intl.formatMessage({ id: "buckmemo.store_link" })}>
					<img
						src={`/img/app-store-badge-${intl.locale}.svg`}
						alt={intl.formatMessage({ id: "download_on_the_app_store" })} />
				</a>
			</div>
		</div>
		<PageFrame>
			<Subheader name="highlights" />
			<Highlight image="/img/auto-fill.png" name="auto_fill" />
			<Highlight image="/img/dynamic-type.jpg" name="dynamic_type" imagePosition="right" />
			<Highlight image="/img/amount-only.png" name="amount_only" label="title" />
			<Highlight image="/img/no-login.png" name="no_login" imagePosition="right" />
			<Highlight image="/img/balance-as-at-today.png" name="balance_as_at_today" label="title" />
			<Subheader name="other_basic_features" />
			<div className={styles.others}>
				<OtherFeature name="income_and_expense" image="/img/income-and-expense.svg" />
				<OtherFeature name="account_management" image="/img/account.svg" />
				<OtherFeature name="transfer" image="/img/transfer.svg" />
				<OtherFeature name="history" image="/img/history.svg" />
				<OtherFeature name="sort_date" image="/img/descending.svg" />
				<OtherFeature name="photo_attachment" image="/img/photo.svg" />
				<OtherFeature name="copy_record" image="/img/copy.svg" />
				<OtherFeature name="statistics" image="/img/statistics.svg" />
				<OtherFeature name="cash_balance_trend" image="/img/cash-balance-trend.svg" />
				<OtherFeature name="search" image="/img/search.svg" />
				<OtherFeature name="past_search_keywords" image="/img/keyword.svg" />
				<OtherFeature name="reminder" image="/img/reminder.svg" />
				<OtherFeature name="auto_repeat" image="/img/auto-repeat.svg" />
				<OtherFeature name="cash_balance_in_today_widget" image="/img/cash-balance-in-today-widget.svg" />
				<OtherFeature name="spotlight_search" image="/img/spotlight-search.svg" />
				<OtherFeature name="home_screen_quick_action" image="/img/home-screen-quick-action.svg" />
			</div>
		</PageFrame>
		<Footer showTermsOfUse={true} showPrivacyPolicy={true} part="buckmemo" showAbout={true} />
	</>)
}