import React from "react"
import styles from "./other-feature.module.scss"
import { useIntl, FormattedMessage } from "gatsby-plugin-intl"

export default ({
	image = "",
	name = ""
}) => {
	const intl = useIntl()
	return (
		<div className={styles.item}>
			<div className={styles.text}>
				<div className={styles.name}><FormattedMessage id={`${name}.title`} /></div>
				<div className={styles.desc}><FormattedMessage id={`${name}.desc`} /></div>
			</div>
			<img src={image} alt={intl.formatMessage({ id: `${name}.title` })} />
		</div>
	)
}